import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import DameAgee from '../../../assets/dame_agee_mains_visage.webp'
import ProcheAidant from '../../../assets/aidante.webp'
import PersonneÂgée from '../../../assets/personne_agee_souriante.webp'
import HommeAcouphene from '../../../assets/homme-oreille.webp'
import Emotions from '../../../assets/emotion-removebg-preview.webp'
import Stress from '../../../assets/stress.webp'
import Sommeil from '../../../assets/sommeil.webp'
import Attention from '../../../assets/attention.webp'
import Confiance from '../../../assets/confiance-removebg-preview.webp'
import '../../../styles/sass/pages/_sophrologie.scss'
import LoaderSpinner from '../../LoaderSpinner/loaderSpinner'

export default function Accompagnements() {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    // Exemple d'utilisation : simulation d'un chargement asynchrone pendant 3 secondes
    document.title =
      "MASophroFabienne - Sophrologie et Accompagnement avec Fabienne au Perreux-sur-Marne | Alzheimer, Aide aux Aidants, Gestion du Stress, Sommeil, Maux de l'Âge, Confiance en Soi, Acouphènes, Gestion des Émotions, Trouble de l'Attention"

    const timer = setTimeout(() => {
      setIsLoading(false)
    }, 3000)

    return () => clearTimeout(timer)
  }, [])
  return isLoading ? (
    <LoaderSpinner />
  ) : (
    <main className="container-fluid responsive-sm">
      <section className="container-fluid main-content">
        <Row className="container-fluid">
          <Col>
            <h1 className="text-center h1-sophrologie">Je vous accompagne</h1>
          </Col>
        </Row>
      </section>
      {/* Section Alzheimer */}
      <section id="alzheimer" className="section-sophrologie">
        <Container>
          <Row className="d-flex">
            <Col className="section-alz">
              <img
                className="img-sophrologie-dame img-fluid img-height"
                src={DameAgee}
                alt="Dame âgée posant les mains sur le visage"
              />

              <Col className="text-center text-alz-position">
                <h2 className="title-sophrologie">
                  Pour la personne atteinte de la maladie d’Alzheimer :
                </h2>
                <p className="text-section-sophrologie">
                  La sophrologie est une{' '}
                  <strong>ressource supplémentaire,</strong> <br /> axée sur la
                  relaxation et le bien-être pour{' '}
                  <strong>améliorer la qualité de vie.</strong> <br />
                  Dans un retour à soi sécurisant et apaisé, <br /> faire
                  émerger des sensations positives et renforcer le lien social.{' '}
                  <br />
                </p>
                <p className="text-section-sophrologie">
                  L’approche est <strong>sensitive et capacitaire, </strong>
                  <br /> pour entretenir les perceptions qui sont le lien à soi
                  et au monde, <br /> mobiliser et valoriser les capacités
                  existantes.
                </p>
                <p className="text-section-sophrologie">
                  {' '}
                  Les séances aident à diminuer l’anxiété, <br /> favorisent la
                  détente physique et mentale, <br /> participent à créer un
                  climat positif dans une ambiance apaisée.
                </p>
                <p className="text-section-sophrologie">
                  {' '}
                  <strong>L’idéal est d’intervenir dès le début.</strong>
                </p>
                <Col className="d-flex justify-content-center align-items-center gap">
                  <Link to="/tarifs" className="cta-link-pink">
                    Découvrir les séances{' '}
                  </Link>

                  <Link to="/contact" className="cta-link">
                    {' '}
                    Être accompagné(e){' '}
                  </Link>
                </Col>
              </Col>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Section Proche aidant */}
      <section id="aidant" className="section-sophrologie">
        <Container>
          <Row className="d-flex mt-0">
            <Col className="section-aidant">
              <img
                className="img-sophrologie-aidant img-fluid img-height"
                src={ProcheAidant}
                alt="Femme tendant la main à une personne"
              />
              <Col className="text-aidant-position text-center">
                <h2 className="title-sophrologie">Pour le proche aidant</h2>
                <p className="text-section-sophrologie">
                  Un temps de pause pour soi, pour{' '}
                  <strong>se ressourcer et prendre soin de soi.</strong>
                  <br />
                </p>
                <p className="text-section-sophrologie">
                  La possibilité de <strong>séances en binôme</strong> avec le
                  proche aidé, <br />
                  pour prendre soin de soi en même temps que de l’autre, <br />
                  et <strong>vivre la relation autrement.</strong>
                </p>
                <Col className="d-flex justify-content-center align-items-center gap">
                  <Link to="/tarifs" className="cta-link">
                    Explorer les options{' '}
                  </Link>

                  <Link to="/contact" className="cta-link-pink">
                    {' '}
                    Discutons-en{' '}
                  </Link>
                </Col>
              </Col>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Section "Personne âgée" */}
      <section id="maux-âge" className="section-sophrologie">
        <Container>
          <Row className="d-flex ">
            <Col className="section-alz">
              <img
                className="img-sophrologie-dame img-fluid img-height"
                src={PersonneÂgée}
                alt="Dame âgée souriante accompagnée par une thérapeute"
              />

              <Col className="text-center text-dame-agee-position mb-5">
                <h2 className="title-sophrologie">Pour la personne âgée</h2>
                <p className="text-section-sophrologie">
                  Intégrer la sophrologie dans son quotidien pour en faire une{' '}
                  <strong>hygiène de vie préventive.</strong> <br />
                  Accompagner les changements et transmettre des techniques{' '}
                  <br />
                  pour <strong>améliorer la qualité de vie</strong> malgré les
                  difficultés liées à l’âge.
                </p>
                <Col className="d-flex justify-content-center align-items-center gap">
                  <Link to="/tarifs" className="cta-link-pink">
                    Voir les tarifs{' '}
                  </Link>

                  <Link to="/contact" className="cta-link">
                    {' '}
                    Demander une aide{' '}
                  </Link>
                </Col>
              </Col>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Section "Acouphènes" */}
      <section id="acouphènes" className="section-sophrologie mb-5">
        <Container>
          <Row className="d-flex mt-0">
            <Col className="section-aidant">
              <img
                className="img-sophrologie-aidant img-fluid img-height"
                src={HommeAcouphene}
                alt="Jeune homme se faisant caresser le visage "
              />
              <Col className="text-aidant-position text-center">
                <h2 className="title-sophrologie">Pour les acouphènes</h2>
                <p className="text-section-sophrologie">
                  <strong>
                    Un protocole spécifiquement adapté de 6 à 8 séances,{' '}
                  </strong>
                  étudié auprès de Patricia Grévin, <br /> pour accompagner au
                  processus naturel d’habituation, <br /> réduire la perception
                  de l’acouphène en l’intégrant dans l’environnement sonore,{' '}
                  <br />
                  <strong> et retrouver une qualité de vie.</strong>
                </p>
                <p className="text-section-sophrologie">
                  Patricia Grévin, soutenue par des médecins ORL, a réalisé
                  l’étude publiée en mai 2020 <br /> dans la revue scientifique
                  «Les annales européennes ORL». <br />
                </p>
                <Col className="d-flex justify-content-center mt-3 mb-3">
                  <a
                    href="https://www.em-consulte.com/article/1361143"
                    className="link-article"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Lire l'article
                  </a>
                </Col>
                <p className="text-section-sophrologie">
                  La prise en charge sophrologique spécifique permet d’
                  <strong>
                    obtenir une diminution significative <br />
                    du caractère intrusif de l’acouphène
                  </strong>{' '}
                  et une amélioration de la qualité de vie.
                </p>
                <p className="text-section-sophrologie">
                  J’interviens en complémentarité, après le diagnostique ORL.
                </p>
                <Col className="d-flex justify-content-center align-items-center gap">
                  <Link to="/tarifs" className="cta-link">
                    Connaître les tarifs{' '}
                  </Link>

                  <Link to="/contact" className="cta-link-pink">
                    {' '}
                    Entrons en contact{' '}
                  </Link>
                </Col>
              </Col>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Section "Émotions" */}
      <section id="émotions" className="section-sophrologie">
        <Container>
          <Row className="d-flex ">
            <Col className="section-alz">
              <img
                className="img-sophrologie-emotion img-fluid img-height"
                src={Emotions}
                alt="Jeune femme tenant un miroir"
              />

              <Col className="text-center text-dame-agee-position mb-5">
                <h2 className="title-sophrologie">Pour les émotions</h2>
                <p className="text-section-sophrologie">
                  L’émotion est <strong>une réaction d’adaptation</strong> face
                  aux contraintes <br />
                  et aux changements de l’environnement.
                </p>
                <p className="text-section-sophrologie">
                  L’accompagnement amène à comprendre son propre mode de
                  fonctionnement, <br /> à{' '}
                  <strong>
                    reconnaître ses émotions et entendre leur utilité.
                  </strong>
                </p>
                <p className="text-section-sophrologie">
                  Puis, transmettre des techniques simples pour aider à{' '}
                  <strong>
                    prendre du recul, s’apaiser <br />
                    et anticiper les situations stressantes pour s’y préparer.
                  </strong>
                </p>
                <p className="text-section-sophrologie">
                  Enfin,{' '}
                  <strong>
                    s’en faire des alliées et découvrir la possibilité d’une
                    réponse adaptée.
                  </strong>
                </p>
                <Col className="d-flex justify-content-center align-items-center gap">
                  <Link to="/tarifs" className="cta-link-pink">
                    Explorer les solutions{' '}
                  </Link>

                  <Link to="/contact" className="cta-link">
                    {' '}
                    Échangeons{' '}
                  </Link>
                </Col>
              </Col>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Section "Stress" */}
      <section id="stress" className="section-sophrologie">
        <Container>
          <Row className="d-flex">
            <Col className="section-aidant ">
              <img
                className="img-sophrologie-aidant img-fluid img-height"
                src={Stress}
                alt="Jeune femme fermant les yeux, les mains au dessus de la tête"
              />

              <Col className="text-aidant-position text-center">
                <h2 className="title-sophrologie">Pour le stress</h2>
                <p className="text-section-sophrologie">
                  Le stress est inévitable dans notre société de
                  sur-sollicitation.
                </p>
                <p className="text-section-sophrologie">
                  {' '}
                  <strong>
                    Nécessaire pour avancer, il peut néanmoins impacter
                    négativement notre qualité de vie.
                  </strong>
                </p>

                <p className="text-section-sophrologie">
                  <strong>
                    {' '}
                    Il pose problème quand il s’inscrit en nous sous forme de
                    symptômes :
                  </strong>
                </p>
                <p>
                  {' '}
                  <strong>cognitifs :</strong> difficulté à se concentrer, à
                  mémoriser, pensées anxieuses envahissantes,
                </p>
                <p>
                  <strong>physiques :</strong> douleurs, troubles du transit,
                  fatigue, hypertension,
                </p>
                <p>
                  <strong>émotionnels :</strong> irritabilité, incapacité à se
                  détendre, sautes d’humeur,
                </p>
                <p>
                  <strong>comportementaux :</strong> troubles de l’alimentation,
                  du sommeil, agitation, isolement...
                </p>

                <p className="text-section-sophrologie">
                  {' '}
                  <strong>
                    La sophrologie est une ressource pour se libérer de
                    l’emprise du stress et faire place <br />à une nouvelle
                    façon d’exister plus sereine et plus confiante.
                  </strong>
                </p>
                <Col className="d-flex justify-content-center align-items-center gap">
                  <Link to="/tarifs" className="cta-link">
                    Voir les tarifs{' '}
                  </Link>

                  <Link to="/contact" className="cta-link-pink">
                    {' '}
                    Parlons de vos besoins{' '}
                  </Link>
                </Col>
              </Col>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Section "Trouble du sommeil" */}
      <section id="sommeil" className="section-sophrologie mb-5">
        <Container>
          <Row className="d-flex mt-0">
            <Col className="section-dame-agee">
              <img
                className="img-sophrologie-dame img-fluid img-height"
                src={Sommeil}
                alt="Couple de personnes âgées endormis dans un lit"
              />
              <Col className="text-center text-dame-agee-position mb-5">
                <h2 className="title-sophrologie">
                  Pour les troubles du sommeil
                </h2>
                <p className="text-section-sophrologie">
                  <strong>
                    {' '}
                    Le sommeil n’est pas une variable ajustable.{' '}
                  </strong>
                  <br />
                  Il correspond à une activité physiologique vitale. <br />
                  Il permet de récupérer sur le plan physique et mental,
                  <br /> et de maintenir l’équilibre physique, psychique et
                  nerveux.
                </p>
                <p className="text-section-sophrologie">
                  <strong>
                    Le manque de sommeil altère la qualité de vie.
                  </strong>
                </p>
                <p className="text-section-sophrologie">
                  <strong>
                    La sophrologie contribue à réduire le stress et l’anxiété ce
                    qui facilite <br />
                    l’endormissement.
                  </strong>
                </p>
                <p className="text-section-sophrologie">
                  Je vous accompagne à instaurer des{' '}
                  <strong>
                    habitudes plus favorables à un sommeil de qualité,
                  </strong>{' '}
                  <br />
                  et vous propose des techniques de respiration et de
                  visualisation pour détendre le <br />
                  corps et l’esprit.
                </p>{' '}
                <p className="text-section-sophrologie">
                  En favorisant la relaxation,{' '}
                  <strong>
                    la pratique de la sophrologie peut aider à retrouver <br />{' '}
                    un sommeil plus réparateur.
                  </strong>
                </p>
                <Col className="d-flex justify-content-center align-items-center gap">
                  <Link to="/tarifs" className="cta-link-pink">
                    Voir les tarifs{' '}
                  </Link>

                  <Link to="/contact" className="cta-link">
                    {' '}
                    Parlons-en ensemble{' '}
                  </Link>
                </Col>
              </Col>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Section "Attention" */}
      <section id="attention" className="section-sophrologie">
        <Container>
          <Row className="d-flex mb-5 ">
            <Col className="section-aidant ">
              <img
                className="img-sophrologie-aidant img-fluid img-height"
                src={Attention}
                alt="Dame âgée avec un léger sourire et le regard franc avec une main sur le visage "
              />

              <Col className="text-aidant-position text-center">
                <h2 className="title-sophrologie">
                  Pour les troubles de l'attention
                </h2>
                <p className="text-section-sophrologie">
                  Je vais vous aider à{' '}
                  <strong>développer votre faculté d’attention.</strong> <br />
                  Entraîner votre présence à votre présent, libéré des tensions
                  du stress et des pensées parasites,
                  <br /> pour être totalement dans l’action du moment.
                </p>
                <p className="text-section-sophrologie">
                  C’est un{' '}
                  <strong>entraînement à muscler votre attention </strong>avec
                  des techniques de respiration, de concentration <br /> et de
                  focalisation, en mobilisant les sens, tout en maintenant un{' '}
                  <strong>
                    état d’esprit calme <br />
                    et une détente corporelle
                  </strong>{' '}
                  plus propices à fixer l’attention.
                </p>
                <p className="text-section-sophrologie">
                  C’est un entraînement qui vous implique, comme pour tout
                  accompagnement sophrologique.
                </p>
                <p className="text-section-sophrologie">
                  <strong>
                    La répétition des techniques améliore la concentration au
                    quotidien, affine les perceptions et la <br /> mémoire
                    sensorielle.
                  </strong>
                </p>

                <Col className="d-flex justify-content-center align-items-center gap">
                  <Link to="/tarifs" className="cta-link">
                    Explorer les tarifs{' '}
                  </Link>

                  <Link to="/contact" className="cta-link-pink">
                    {' '}
                    Échangeons à ce sujet{' '}
                  </Link>
                </Col>
              </Col>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Section Confiance en soi */}
      <section id="confiance" className="section-sophrologie mb-5">
        <Container>
          <Row className="d-flex mt-0">
            <Col className="section-dame-agee">
              <img
                className="img-sophrologie-dame img-fluid img-height"
                src={Confiance}
                alt="Couple de personnes âgées endormis dans un lit"
              />
              <Col className="text-center text-dame-agee-position mb-5">
                <h2 className="title-sophrologie">Pour la confiance en soi</h2>
                <p className="text-section-sophrologie">
                  <strong>
                    La confiance en soi est un sentiment qui se construit et se
                    travaille.
                  </strong>
                </p>
                <p className="text-section-sophrologie">
                  Avoir une bonne image de soi, de l’estime pour soi. <br />
                  Se sentir apprécié, digne d’intérêt et à la hauteur des
                  situations. <br />
                  Croire en ses compétences sans être dépendant du regard des
                  autres.
                </p>
                <p className="text-section-sophrologie">
                  <strong>
                    {' '}
                    La sophrologie aide à développer l’estime de soi, socle de
                    la confiance en soi.
                  </strong>
                </p>
                <p className="text-section-sophrologie">
                  Vous libérer des pensées négatives pour les empêcher de vous
                  envahir. <br />
                  <strong>
                    Les remplacer par des affirmations positives qui renforcent
                    l’estime de soi. <br />
                  </strong>
                  Cultiver l’optimisme ancre une dynamique positive en soi.{' '}
                  <br />
                  Prendre conscience de ses qualités, compétences et talents{' '}
                  <br />
                  permet de s’apprécier à sa juste valeur et de dégager une
                  énergie positive.
                </p>
                <p className="text-section-sophrologie">
                  <strong>
                    {' '}
                    La confiance en soi c’est une posture physique et psychique.{' '}
                    <br /> Travailler la posture physique retentit sur la
                    posture psychique.
                  </strong>
                </p>
                <p className="text-section-sophrologie">
                  {' '}
                  <strong>
                    C’est un entraînement auquel je peux vous accompagner.
                  </strong>
                </p>
                <Col className="d-flex justify-content-center align-items-center gap">
                  <Link to="/tarifs" className="cta-link-pink">
                    Découvrir{' '}
                  </Link>

                  <Link to="/contact" className="cta-link">
                    {' '}
                    Discutons-en{' '}
                  </Link>
                </Col>
              </Col>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  )
}
