import React from 'react'
import BreathingTimer from '../BreathingTimer/breathingTimer'
import Logo  from '../../assets/logo.webp'
import '../../styles/sass/components/_maintenance.scss'

export default function MaintenancePage() {
  return (
    <main className="container-fluid responsive-sm flex align-items-center">
       <img
              className="logo_maintenance"
              src={Logo}
              alt="logo MASophroFabienne"
              aria-hidden="true"
            />
     
      <h1 className="h1-error text-center d-none d-md-block">
        Mon site de sophrologie <br />est en cours de préparation.
      </h1>
     
      <h1 className="text-center d-block d-md-none">
        Mon site de sophrologie est <br />en cours de préparation.
      </h1>
      {/* Emoji Zen */}
      <div
        className="zen"
      ></div>
      {/* Animation de respiration */}
      <section className="container text-center p-5">
        <BreathingTimer />
      </section>
    </main>
  )
}
