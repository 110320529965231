import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import '../../../styles/sass/pages/_tarifs.scss'
import LoaderSpinner from '../../LoaderSpinner/loaderSpinner'

export default function Tarifs() {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    document.title = "MASophroFabienne - Tarifs | Découvrez nos offres de séances de sophrologie individuelles, en binôme et en groupe.";

    // Exemple d'utilisation : simulation d'un chargement asynchrone pendant 3 secondes
    const timer = setTimeout(() => {
      setIsLoading(false)
    }, 3000)

    return () => clearTimeout(timer)
  }, [])

  return isLoading ? (
    <LoaderSpinner />
  ) : (
    <main className="container-fluid responsive-sm">
      <h1 className="text-center mb-0">Prestations / Tarifs </h1>
      <Container xs={1} md={2} lg={3} className="g-4">
        <Row className="mt-5 row-center">
          <h2 className="h2-centered mb-1">
            Séance de sophrologie à Domicile* ou en Visio
          </h2>

          <Col className="col-tarif">
            <Link to="/contact" className="card-link">
              <Card>
                <Card.Body>
                  <Card.Title className="text-center">
                    Séance Individuelle
                  </Card.Title>
                  <Card.Text>
                    <strong>Prix :</strong> 60€ la séance
                    <br />
                    <strong>Contenu :</strong> Séance personnalisée, adaptée aux
                    besoins exprimés.
                    <br />
                    <strong>Durée :</strong> 1h
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <Button variant="link" className="btn-reserver">
                    Réserver une séance
                  </Button>
                </Card.Footer>
              </Card>
            </Link>
          </Col>

          <Col className="col-tarif">
            <Link to="/contact" className="card-link">
              <Card>
                <Card.Body>
                  <Card.Title className="text-center">
                    Séance en Binôme
                  </Card.Title>
                  <Card.Text>
                    <strong>Prix :</strong> 60€ la séance
                    <br />
                    <strong>Contenu :</strong> Séance pour 2 personnes, adaptée
                    aux besoins spécifiques aidant/aidé.
                    <br />
                    <strong>Durée :</strong> 1h
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <Button variant="link" className="btn-reserver">
                    Réserver une séance
                  </Button>
                </Card.Footer>
              </Card>
            </Link>
          </Col>
        </Row>

        <Row className="row-center mt-2">
          <h2 className="h2-centered mb-1">
            Séance de sophrologie en Institution
          </h2>

          <Col className="col-tarif">
            <Link to="/contact" className="card-link">
              <Card>
                <Card.Body>
                  <Card.Title className="text-center">
                    Séance de Groupe
                  </Card.Title>
                  <Card.Text>
                    <strong>Prix :</strong> Sur devis
                    <br />
                    <strong>Contenu :</strong> Séance selon les besoins du
                    groupe et de l'institution.
                    <br />
                    <strong>Durée :</strong> 1h
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <Button variant="link" className="btn-reserver">
                    Demander un devis
                  </Button>
                </Card.Footer>
              </Card>
            </Link>
          </Col>

          <Col className="col-tarif">
            <Link to="/contact" className="card-link">
              <Card>
                <Card.Body>
                  <Card.Title className="text-center">
                    Séance Individuelle
                  </Card.Title>
                  <Card.Text>
                    <strong>Prix :</strong> 60€ la séance
                    <br />
                    <strong>Contenu :</strong> Séance personnalisée, adaptée aux
                    besoins exprimés.
                    <br />
                    <strong>Durée :</strong> 1h
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <Button variant="link" className="btn-reserver">
                    Réserver une séance
                  </Button>
                </Card.Footer>
              </Card>
            </Link>
          </Col>

          <Col className="col-tarif">
            <Link to="/contact" className="card-link">
              <Card>
                <Card.Body className="card-body-bi">
                  <Card.Title className="text-center">
                    Séance en Binôme
                    <p className="mb-0 mt-0">
                      (Proche/Résident)
                    </p>
                  </Card.Title>

                  <Card.Text>
                    <strong>Prix :</strong> 60€ la séance
                    <br />
                    <strong>Contenu :</strong> Séance pour 2 personnes, adaptée
                    aux besoins spécifiques du binôme.
                    <br />
                    <strong>Durée :</strong> 1h
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <Button variant="link" className="btn-reserver">
                    Réserver une séance
                  </Button>
                </Card.Footer>
              </Card>
            </Link>
          </Col>
        </Row>
      </Container>
      <p className="text-center mt-5 mb-0 d-block d-md-none">
        *Je me déplace pour vous offrir mes services dans des villes telles que{' '}
        Le Perreux-sur-Marne, Nogent-sur-Marne, Bry-sur-Marne et leurs environs,
        sur demande.
      </p>
      <p className="text-center mt-5 mb-0 d-none d-md-block">
        *Je me déplace pour vous offrir mes services dans des villes telles que{' '}
        <br />
        Le Perreux-sur-Marne, Nogent-sur-Marne, Bry-sur-Marne et leurs environs,
        sur demande.
      </p>
    </main>
  )
}
