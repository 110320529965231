// apicall.js
export const submitForm = async (formData) => {
  try {
    const response = await fetch('https://apimsf.netlify.app/.netlify/functions/submit-form', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });
    if (!response.ok) {
      throw new Error('Erreur réseau');
    }

    return await response.json();
  } catch (error) {
    console.error("Une erreur s'est produite lors de l'envoi de l'email :", error);
    throw error;
  }
};

