import React from 'react'
import '../../styles/sass/components/_loaderSpinner.scss'

export default function LoaderSpinner() {
  return (
    <main className="background">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </main>
  )
}
