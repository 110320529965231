import React, { useState, useEffect } from 'react'

import '../../styles/sass/components/_breathingTime.scss'

const BreathingButton = () => {
  const [timeLeft, setTimeLeft] = useState('')
  const [timerRunning, setTimerRunning] = useState(false)
  const [breathingActive, setBreathingActive] = useState(false) // Nouvel état pour suivre l'état du minuteur
  const [buttonText, setButtonText] = useState('Démarrer')
  const [breathingText, setBreathingText] = useState('Inspirez')

  useEffect(() => {
    let timerInterval
    if (timerRunning) {
      timerInterval = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime === 0) {
            clearInterval(timerInterval)
            setTimerRunning(false)
            setBreathingActive(false)
            setTimeLeft('')
            setButtonText('Démarrer')
            setBreathingText('Inspirez')
            return 0
          } else {
            return prevTime - 1
          }
        })
      }, 1500)

      // Change le texte toutes les 3,1 secondes
      let interval = setInterval(() => {
        setBreathingText((prevText) => {
          return prevText === 'Inspirez' ? 'Expirez' : 'Inspirez'
        })
      }, 3100)

      return () => {
        clearInterval(timerInterval)
        clearInterval(interval)
      }
    }
  }, [timerRunning])

  const handleClick = () => {
    if (!timerRunning) {
      setTimeLeft(15)
      setTimerRunning(true)
      setBreathingActive(true)
      setButtonText('Arrêter')
    } else {
      setTimerRunning(false)
      setBreathingActive(false)
      setButtonText('Démarrer') // Change le texte du bouton pour "Démarrer" lorsque le minuteur est arrêté manuellement
      setTimeLeft('')
    }
  }

  return (
    <div>
      <p className="mb-3">Pour patienter, je vous propose cet exercice de respiration.</p>
      <p className="mb-3">
        Prenez une pause et reconnectez-vous à votre souffle. <br />
        Respirez profondément en inspirant et en expirant calmement pendant 15
        secondes. <br />
        Laissez-vous emporter par le rythme apaisant de votre respiration.
      </p>
      <div className="d-flex justify-content-center align-items-center flex-column">
        <button onClick={handleClick} className="button-text breathing-button">
          {buttonText}
        </button>
        <div className="mb-1 timer">{timeLeft}</div>
        {breathingActive && (
          <div className=" mt-3 breathing-circle-container ">
            <p className="mt-4 breathing-text">{breathingText}</p>
            <span className="breathing-circle mb-3"></span>
          </div>
        )}
      </div>
    </div>
  )
}

export default BreathingButton
