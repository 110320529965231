import React from 'react'
import { Link } from 'react-router-dom'
import '../../../styles/sass/pages/_error.scss'

export default function Error() {
  return (
    <main className="container-fluid responsive-sm">
      <h1 className="h1-error text-center d-none d-md-block">
        Oups.... Cette page n'existe page
      </h1>

      <h1 className="text-center d-block d-md-none">
        Oups.... <br /> Cette page n'existe page
      </h1>
      <div className="d-flex justify-content-center ">
        <Link to="/" className="cta-link">
          Retourner à l'accueil
        </Link>
      </div>
    </main>
  )
}
